import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/frontend/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowGoUp"] */ "/vercel/path0/apps/frontend/src/modules/arrow-up/ArrowUp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/vercel/path0/apps/frontend/src/modules/cookie-consent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/vercel/path0/apps/frontend/src/modules/navigation/Navigation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.5.0_react@18.3.1/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/pure-react-carousel@1.32.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/pure-react-carousel/dist/react-carousel.es.css");
